<div class="animated fadeIn" [class.oos-overlay-per-quantity]="isOutOfStockPerQuantity()"
  [class.oos-overlay]="isOutOfStock()">
  <p class="oos-overlay--title">Out of stock</p>
  <p class="oos-overlay--description">
    @if (!isOutOfStockPerQuantity()) {
    @if (hasNotify()) {
    This product is currently out of stock, let us know if you want to be notify when it’s available again
    } @else {
    This product is temporarily out of stock.
    }
    } @else {
    There are not that many units available. Please select a different quantity.
    }
  </p>

  @if(!isOutOfStockPerQuantity() && hasNotify()) {
  <div class="w-100 d-flex flex-row-reverse">
    @if (hasSession()) {
    <button type="button" class="w-50 btn btn-primary text-center" [class.w-100]="!isFromSummary()"
      (click)="flipCardType()">
      Notify me
    </button>
    }
    @else {
    <button type="button" class="w-50 btn btn-primary text-center" [class.w-100]="!isFromSummary()"
      (click)="openModalCheckAddress()">
      Sign Up
    </button>
    }
  </div>
  }
</div>
