@if (card().product) {
<div class="product-card"
  (click)="subscribeAndcustomizeBox() && cardType() !== cardTypes.subscribe ? flipCardType(cardTypes.subscribe) : null"
  (mouseenter)="showText = true" (mouseleave)="showText = false"
  [class.suggested]="card().product.suggestedShoppingOption"
  [class.product-card--subscription]="cardType() == cardTypes.subscribe"
  [class.product-card--summary]="cardType() == cardTypes.summary"
  [class.product-card--notify]="cardType() == cardTypes.notify || cardType() == cardTypes.notified"
  [class.product-card--check-address]="cardType() == cardTypes.checkAddress">

  <div class="product-card__body">

    @switch (cardType()) {
    @case (cardTypes.summary) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">

      <!-- Card Header -->
      <div class="ps-0 pt-1 bg-transparent" [ngClass]="{'active-filter-blur': isOutOfStock()}">
        <p class="mb-1 text-body-secondary h8-regular">
          Summary
        </p>
        <p class="fw-bold h4-bold">
          {{ card().product?.name }}
        </p>
      </div>

      @if (isOutOfStock() || isOutOfStockPerQuantity()) {
      <app-out-of-stock [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()"
        [hasNotify]="hasNotify" [isFromSummary]="true" (outFlipCardType)="flipCardType($event)"
        (outModalCheckAddress)="openModalCheckAddress()"></app-out-of-stock>
      } @else {
      <section [class.opacity-0]="isOutOfStock()">
        <div class="p-0 mt-2 d-flex flex-column gap-2">
          <!-- Product Customizations  -->
          <div class="row">
            <!-- Product Customizations  -->
            @if (isGiftCard()) {
            <div class="col-lg-6 col-md-12">
              <p class="m-0 h7-semibold">Amount:</p>
            </div>
            }
            <div [class.col-md-12]="isGiftCard()" [class.col-lg-6]="isGiftCard()" [class.col-12]="!isGiftCard()">
              @if (productAttributes.length) {
              @for (attr of productAttributes; track $index) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                <mat-select [value]="selectedAttribute()?.value?.id || ''"
                  (selectionChange)="onAttributeChange(attr.id, $event)">
                  @for (item of attr.values; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              }
              }
              @if (card().product?.packages?.length) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">Package</mat-label>
                <mat-select [value]="selectedPackage()?.id || ''" (selectionChange)="onPackageChanged($event.value)">
                  @for (item of card().product?.packages; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              }
            </div>
          </div>

          @if (!isGiftCard()) {
          <!-- Quantity buttons -->
          <div class="row row-cols-2 d-flex justify-content-end align-items-center">
            <div class="col-7">
              <div class="card-price d-flex gap-1 flex-row align-items-center">
                <h5 class="card-title mb-0">{{ (selectedAttributeCardConfig().price) | currency}}</h5>
                @if(selectedAttributeCardConfig().hasDiscount) {
                <small class="text-body-tertiary fw-bold text-decoration-line-through">
                  {{selectedAttributeCardConfig().originalPrice | currency}}
                </small>
                }
              </div>
            </div>
            <div class="col-5 quantity">
              @if (wasValueHigher10) {
              <small class="how-many-label d-none d-sm-block">How many?</small>
              }
              <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                <mat-label>Quantity</mat-label>
                @if( wasValueHigher10 || newQuantity() >= 10 || (newQuantity() >= 10 && wasValueHigher10) ){
                <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                  [disabled]="!!productInCart()" (change)="onInputQuantityChange($event)" [value]="newQuantity()"
                  (input)="onInputChange($event)">
                }
                @else{
                <mat-select [disabled]="!!productInCart()" [value]="newQuantity()"
                  (selectionChange)="onQuantityChange($event)">
                  @for(number of [1,2,3,4,5,6,7,8,9]; track $index){
                  <mat-option [value]="number">{{ number }}</mat-option>
                  }
                  <mat-option value="10">10+</mat-option>
                </mat-select>
                }
              </mat-form-field>
            </div>
          </div>
          }

          <hr class="border-2 mb-0">

          <!-- Total calculates -->
          <div class="row row-cols-2 mt-3 d-flex justify-content-between align-items-center">
            <div class="quantity">
              <h5 class="h3-semibold text-secondary-700">Total</h5>
            </div>
            <div class="text-right">
              <h5 class="h5-bold">
                {{ summaryTotalPrice() | currency }}
              </h5>
            </div>
          </div>
        </div>
      </section>
      }

      <!-- Action button -->
      @if (isGiftCard()) {
      <button (click)="purchaseGiftCardClicked()" class="btn btn-primary mt-2">Purchase</button>
      } @else {
      @if (!(!isOutOfStockPerQuantity() && hasNotify)) {
      @if(!customizeBox) {
      <div class="action-buttons">
        @if (signalsStoreService.hasSession()) {
        @if ((selectedAttributeCardConfig().isSubscription)) {
        @if (card().product?.bundle?.items?.length) {
        @if(stockSignal().size) {

        @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
        <button type="button" (click)="addProductToCart(false, $event)" [disabled]="isDisabledActionButtons()"
          class="btn btn-secondary w-50">
          {{getButtonText({isBundle: true, isSubscription: false})}}
        </button>
        }

        <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
          class="btn btn-outline-secondary w-50">
          {{getButtonText({isBundle: true, isSubscription: true})}}
        </button>

        } @else {
        <p class="placeholder-glow mb-0 w-100">
          <span class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
            style="height: 36px;"></span>
        </p>
        }
        } @else {
        @if(stockSignal().size) {
        @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
        <button type="button" (click)="addProductToCart(false, $event)" [disabled]="isDisabledActionButtons()"
          class="btn btn-outline-secondary w-50">
          {{getButtonText({isBundle: false, isSubscription: false})}}
        </button>
        }
        <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
          class="btn btn-secondary w-50">
          {{getButtonText({isBundle: false, isSubscription: true})}}
        </button>
        } @else {
        <p class="placeholder-glow mb-0 w-100">
          <span class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
            style="height: 36px;"></span>
        </p>
        }
        }
        } @else {
        @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
        <button type="button" (click)="addProductToCart(false, $event)"
          [disabled]="isDisabledActionButtons() || !marketStatus().isOpen" class="btn btn-secondary w-50">
          {{getButtonText({isBundle: false, isSubscription: false})}}
        </button>
        } @else {
        <p class="placeholder-glow mb-0 w-100">
          <span class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
            style="height: 36px;"></span>
        </p>
        }
        }
        } @else {
        <button type="button" (click)="openModalCheckAddress()" class="btn btn-secondary w-50">
          Sign Up
        </button>
        }
      </div>
      } @else {
      <div class="product-card__action">
        <button type="button" (click)="flipCardType(cardTypes.subscribe, true)"
          class="btn btn-secondary d-flex gap-2 align-items-center">
          <span class="material-symbols-outlined">
            add
          </span>
        </button>
      </div>
      }
      }
      }
    </section>
    }
    @case (cardTypes.main) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">

      <div class="product-card__img-wrap" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">

        <!-- Fav Icon -->
        @if (signalsStoreService.hasSession()) {
        @if(!isSignupFlow()) {
        <div class="product-card__pre-head">

          <!-- Like -->

          @if (isFavorite()) {
          <span (click)="handleFavoriteChange()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
            </svg>
          </span>
          } @else {
          <span (click)="handleFavoriteChange()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
            </svg>
          </span>
          }

          <!-- Dislike -->

          @if (isDislike()) {
          <span (click)="prevDislikeProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
            </svg>
          </span>
          } @else {
          <span (click)="prevDislikeProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
            </svg>
          </span>
          }

        </div>
        }
        }

        <!-- Sale Badge -->
        @if(selectedAttributeCardConfig().hasDiscount && selectedAttributeCardConfig().isOnSale) {
        <div class="sale-badge">
          <span class="material-symbols-outlined">
            percent
          </span>
        </div>
        }

        <!-- Image -->
        @if(!isSignupFlow()) {
        <a [routerLink]="['/shop/'+card().product?.productUrl]">
          @defer (on viewport; prefetch on immediate) {
          <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
            alt="Image of {{card().product?.name}}" (error)="handleImageError($event)" class="product-card__img"
            [ngClass]="{'disliked': isDislike()}">
          } @placeholder {
          <div style="height: 150px;"></div>
          }
        </a>
        } @else {
        <a>
          @defer (on viewport; prefetch on immediate) {
          <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
            (error)="handleImageError($event)" class="product-card__img" alt="Image of {{card().product?.name}}">
          } @placeholder {
          <div style="height: 150px;"></div>
          }
        </a>
        }

        <!-- Action button -->
        @if (!isOutOfStock() && !isOutOfStockPerQuantity() && !isSignupFlow()) {
        @if(!customizeBox) {
        <div class="product-card__action">
          <div class="d-grid gap-2">
            @if (signalsStoreService.hasSession()) {
            @if (selectedAttributeCardConfig().isSubscription) {
            @if (card().product?.bundle?.items?.length) {
            <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
              @if(stockSignal().size) {
              <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
                class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                <span class="material-symbols-outlined">
                  cached
                </span>
              </button>
              @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
              <button type="button" (click)="addProductToCart(false, $event)" [disabled]="isDisabledActionButtons()"
                class="btn btn-secondary d-flex gap-2 align-items-center">
                <span class="material-symbols-outlined">
                  add
                </span>
              </button>
              }
              } @else {
              <p class="placeholder-glow mb-0 w-100">
                <span
                  class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                  style="height: 36px;"></span>
              </p>
              }
            </div>
            }
            @else {
            <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
              @if(stockSignal().size) {
              <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
                class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                <span class="material-symbols-outlined">
                  cached
                </span>
              </button>
              @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
              <button type="button" (click)="addProductToCart(false, $event)" [disabled]="isDisabledActionButtons()"
                class="btn btn-secondary d-flex gap-2 align-items-center">
                <span class="material-symbols-outlined">
                  add
                </span>
              </button>
              }
              } @else {
              <p class="placeholder-glow mb-0 w-100">
                <span
                  class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                  style="height: 36px;"></span>
              </p>
              }
            </div>
            }
            }
            @else {
            @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
            <button type="button" (click)="addProductToCart(false, $event)"
              [disabled]="isDisabledActionButtons() || !marketStatus().isOpen"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              <span class="material-symbols-outlined">
                add
              </span>
            </button>
            } @else {
            <p class="placeholder-glow mb-0 w-100">
              <span
                class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                style="height: 36px;"></span>
            </p>
            }
            }
            }
            @else {
            <button type="button" (click)="openModalCheckAddress()"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              <!-- Sign Up -->
              <span class="material-symbols-outlined">
                add
              </span>
            </button>
            }
          </div>
        </div>
        } @else {
        <div class="product-card__action">
          <button type="button" (click)="flipCardType(cardTypes.subscribe, true)"
            class="btn btn-secondary d-flex gap-2 align-items-center">
            <!-- <i class="fa fa-pen-to-square"></i>
              Customize box -->
            <span class="material-symbols-outlined">
              add
            </span>
          </button>
        </div>
        }
        }

        <!-- Subscribed or added -->
        <div class="card-action-labels">
          @if (existsInOrder().subscription){
          <span>Subscribed</span>
          }

          @if (existsInOrder().common){
          <span>Added</span>
          }
        </div>

        <!-- Hovered Button over main image -->
        @if (card().product?.bundle?.items?.length) {
        <div class="info-badge">
          <button type="button" class="rolling-info" [class.active]="showText"
            (click)="openModalWhatsInside(card().product, $event)">

            @if (!showText) {
            <i class="rolling-info-icon fas fa-info-circle"></i>
            }

            <span class="rolling-info__text" [class.active]="showText">
              What's inside
            </span>
          </button>
        </div>
        }

        @if (!isSignupFlow()) {
        @if (!marketStatus().isOpen && !selectedAttributeCardConfig().isSubscription) {
        <div class="d-grid position-absolute bottom-0 pb-3 w-100">
          <div class="mb-0 w-100 alert py-2 px-3 alert-warning d-flex align-items-center animated fadeIn" role="alert">
            @if (isMobile()) {
            <div class="h8-regular text-center">
              Available <b>on {{marketStatus().openingDay | titlecase}}</b>
            </div>
            } @else {
            <div class="h7-regular">
              You'll be <b>able to add</b> items to your cart on <b>{{marketStatus().openingDay | titlecase}}</b>
              Morning
            </div>
            }
          </div>
        </div>
        }
        }
      </div>

      <!-- Card Header - Title and Subtitle -->
      <div class="product-card__head" [ngClass]="{'card-img active-filter-blur h-auto': isOutOfStock()}">
        @if(!customizeBox && !isSignupFlow()) {
        <a [routerLink]="['/shop/'+card().product?.productUrl]">
          <h3 class="product-card__title" [ngbTooltip]="card().product?.name">{{card().product?.name}}</h3>
        </a>
        } @else {
        <a>
          <h3 class="product-card__title" [ngbTooltip]="card().product?.name">{{card().product?.name}}</h3>
        </a>
        }
        <h5 class="product-card__subtitle">
          {{card().product?.producer?.name || ''}}
        </h5>
      </div>

      @if (isOutOfStock() || isOutOfStockPerQuantity()) {
      <app-out-of-stock [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()"
        [hasNotify]="hasNotify" [isFromSummary]="false" (outFlipCardType)="flipCardType($event)"
        (outModalCheckAddress)="openModalCheckAddress()"></app-out-of-stock>
      } @else {
      <section [class.opacity-0]="isOutOfStock()">
        <div class="product-card__meta">
          <div class="product-card__meta--item is-col-2">
            <div class="d-grid">
              <div class="d-flex gap-1 d-flex gap-1 flex-row align-items-center">
                <h5 class="product-card__price">{{ selectedAttributeCardConfig().price | currency }}</h5>
                @if(selectedAttributeCardConfig().hasDiscount) {
                <small class="product-card__price--line-through fw-bold text-decoration-line-through">
                  {{selectedAttributeCardConfig().originalPrice | currency}}
                </small>
                }
              </div>
              @if (selectedAttributeCardConfig().display) {
              <p class="product-card__info">{{selectedAttributeCardConfig().display || ''}}</p>
              }
            </div>

            <!-- Quantity buttons -->
            <div class="col quantity" [class.mt-how-many]="wasValueHigher10">
              @if (wasValueHigher10) {
              <small class="how-many-label d-none d-sm-block">How many?</small>
              }
              <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                @if( wasValueHigher10 || newQuantity() >= 10 || (newQuantity() >= 10 && wasValueHigher10) ){
                <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                  (change)="onInputQuantityChange($event)" (keyup)="onKeyUp($event)" [value]="newQuantity()">
                }
                @else{
                <mat-select [value]="newQuantity()" (selectionChange)="onQuantityChange($event)">
                  @for(number of [1,2,3,4,5,6,7,8,9]; track $index){
                  <mat-option [value]="number">{{ number }}</mat-option>
                  }
                  <mat-option value="10">10+</mat-option>
                </mat-select>
                }
              </mat-form-field>
            </div>

          </div>

          <!-- Product Customizations  -->
          @if (card().settings?.showAttributes) {
          <div class="row">
            <div class="col-12 d-flex gap-1">
              @if (productAttributes.length) {
              @for (attr of productAttributes; track $index) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                @if (card().product?.variantId || attr.values.length === 1) {
                <input matInput type="text" readonly="true" [value]="selectedAttribute()?.value.name">
                } @else {
                <mat-select [value]="selectedAttribute()?.value?.id || ''"
                  (selectionChange)="onAttributeChange(attr.id, $event)">
                  @for (item of attr.values; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
                }
              </mat-form-field>
              }
              }
              @if (card().product?.packages?.length) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">Package</mat-label>
                @if (card().product?.packages?.length === 1) {
                <input matInput type="text" readonly="true" [value]="selectedPackage()?.name">
                } @else {
                <mat-select [value]="selectedPackage()?.id || ''" (selectionChange)="onPackageChanged($event.value)">
                  @for (item of card().product?.packages; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
                }
              </mat-form-field>
              }
            </div>
          </div>

          } @else {
          <div class="height-product-custom"></div>
          }


          <!-- Tags Icons -->
          <div class="my-2">
            <ul class="product-card__tags">
              @for (tag of card().product?.tags; track $index) {
              <li>
                {{tag.name}}
              </li>
              }
            </ul>
          </div>

        </div>

      </section>
      }

    </section>
    }
    @case (cardTypes.notify) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">
      <div class="row">
        <i class="fas fa-times text-end close-mark" (click)="flipCardType(cardTypes.main)"></i>
      </div>
      <h4 class="text-center mt-2 px-2">
        Unfortunately, this product is out of stock.
      </h4>
      <p>If you wold like us to notify you when we receive a new shipment, please enter your information below.
      </p>
      <mat-radio-group [(ngModel)]="notifySelectedOption">
        <div class="row row-cols-1">
          <div class="col">
            <div class="d-flex flex-row align-items-center">
              <mat-radio-button
                [value]="{email: signalsStoreService.sessionSignal()?.accountInfo?.email}"></mat-radio-button>
              <label class="email-container" #emailContainer>
                <div class="email-text" #emailText>{{signalsStoreService.sessionSignal()?.accountInfo?.email}}</div>
              </label>
            </div>
          </div>
          <div class="col text-center">
            <b>or</b>
          </div>
          <div class="col">
            <div class="d-flex flex-row align-items-center">
              <mat-radio-button
                [value]="{phone: signalsStoreService.sessionSignal()?.accountInfo?.phone}"></mat-radio-button>
              <label>{{signalsStoreService.sessionSignal()?.accountInfo?.phone || "" | phone}}</label>
            </div>
          </div>
        </div>
      </mat-radio-group>
      <!-- Action button -->
      <div class="card-footer p-0 mt-1 mt-auto">
        <div class="row">
          <div class="col">
            <button type="button"
              (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Cancel
            </button>
          </div>
          <div class="col">
            <button type="button" (click)="notifyMe()" class="btn btn-primary d-flex gap-2 align-items-center">
              Notify Me!
            </button>
          </div>
        </div>
      </div>
    </section>
    }
    @case (cardTypes.notified) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">
      <div class="row">
        <i class="fas fa-times text-end close-mark"
          (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"></i>
      </div>
      <div class="d-flex h-100 justify-content-center align-items-center px-1 flex-column text-center">
        <h4 class="">
          You'll be notified!
        </h4>
        <p>Thank you for your interest in this product. We will let you know when it's back in stock.</p>
      </div>
    </section>
    }
    @case (cardTypes.subscribe) {
    <i class="fas fa-times close-mark"
      (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main, false, $event)"></i>
    <section class="animated fadeIn ">

      <div>
        <h4 class="text-center h3-semibold">Product subscription</h4>
        @if (subscribeAndcustomizeBox()) {
        <p>How often would you want to receive your farmbox?</p>
        } @else {
        <p>When would you like to receive your subscription item(s)?</p>
        }
        <mat-form-field class="rounded-selector mb-3 d-flex" subscriptSizing="dynamic">
          <mat-label> Frequency </mat-label>
          <mat-select (selectionChange)="onChangeFrequency($event)" [value]="selectedFrequency?.id">
            @for(freq of frequencies(); track $index){
            <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (!subscribeAndcustomizeBox()) {
        @if (!isTemporaryRouteChange()) {
        <mat-checkbox [checked]="true" class="mb-3"
          (change)="initiateSubscriptionImmediatly.set($event.checked)">Initiate my subscription
          immediately</mat-checkbox>
        }
        @if (initiateSubscriptionImmediatly()) {
        <app-empty-message [width100]="true" [mt0]="true"
          [message]="'Next Delivery: ' + deliveriesService.deliveryZoneInfo()?.readeableDeliveryDate"></app-empty-message>
        } @else {
        <mat-form-field class="w-100 mb-3 animated fadeIn">
          <mat-label>Start subscription date</mat-label>
          <input matInput [min]="minDate()" [matDatepicker]="startSubscription" [matDatepickerFilter]="dateFilter"
            readonly (dateChange)="onDateChange($event)" (click)="startSubscription.open()">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="startSubscription"></mat-datepicker-toggle>
          <mat-datepicker [yPosition]="'below'" #startSubscription disabled="false"></mat-datepicker>
        </mat-form-field>
        }
        }
      </div>
      <div class="card-footer p-0 mt-1 mt-auto">
        <div class="row">
          @if (subscribeAndcustomizeBox()) {
          <div class="col">
            <button type="button" (click)="continue()"
              [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Subscribe & Continue
            </button>
          </div>
          } @else {
          <div class="col">
            <button type="button"
              (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"
              class="btn btn-outline-secondary d-flex gap-2 align-items-center">
              Cancel
            </button>
          </div>
          <div class="col">
            <button type="button" (click)="addProductToCart(true, $event)"
              [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Subscribe
            </button>
          </div>
          }
        </div>
      </div>

    </section>
    }
    @case (cardTypes.preferences) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">
      <div class="product-card__img-wrap">

        <!-- Fav Icon -->
        @if (signalsStoreService.hasSession()) {
        <div class="product-card__pre-head">

          <!-- Like -->

          @if (isFavorite()) {
          <span (click)="handleFavoriteChange()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" />
            </svg>
          </span>
          } @else {
          <span (click)="handleFavoriteChange()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
            </svg>
          </span>
          }

          <!-- Dislike -->

          @if (isDislike()) {
          <span (click)="prevDislikeProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="M240-840h400v520L360-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 1.5-15t4.5-15l120-282q9-20 30-34t44-14Zm480 520v-520h160v520H720Z" />
            </svg>
          </span>
          } @else {
          <span (click)="prevDislikeProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#38424b">
              <path
                d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
            </svg>
          </span>
          }

        </div>
        }

        <!-- Image -->
        @defer (on viewport; prefetch on immediate) {
        <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
          (error)="handleImageError($event)" class="product-card__img" [ngClass]="{'disliked': isDislike()}"
          alt="Image of {{card().product?.name}}">
        } @placeholder {
        <div style="height: 150px;"></div>
        }

        @if (isFavorite()) {
        <div class="liked">
        </div>
        }

      </div>

      <div class="product-card__head">
        <h3 class="product-card__title" [ngbTooltip]="card().product?.name">{{card().product?.name ?? ''}}</h3>
        <h5 class="product-card__subtitle">{{card().product?.producer?.name ?? ''}}</h5>
      </div>

    </section>
    }
    }

  </div>

  <!-- @if ((isOutOfStock() || isOutOfStockPerQuantity()) && (cardType() === cardTypes.main || cardType() ===
  cardTypes.summary)) {
  <div class="animated fadeIn" [class.oos-overlay-per-quantity]="isOutOfStockPerQuantity()"
    [class.oos-overlay]="isOutOfStock()">
    <p class="oos-overlay--title">Out of stock</p>
    <p class="oos-overlay--description">
      @if (!isOutOfStockPerQuantity()) {
      @if (hasNotify) {
      This product is currently out of stock, let us know if you want to be notify when it’s available again
      } @else {
      This product is temporarily out of stock.
      }
      } @else {
      There are not that many units available. Please select a different quantity.
      }
    </p>

    @if(!isOutOfStockPerQuantity() && hasNotify) {
    <div class="w-100 d-grid gap-2">
      @if (signalsStoreService.hasSession()) {
      <button type="button" class="w-100 btn btn-primary text-center" (click)="flipCardType(cardTypes.notify)">
        Notify me
      </button>
      }
      @else {
      <button type="button"
        class="w-100 btn btn-primary text-center d-flex flex-row gap-3 justify-content-center align-items-center"
        (click)="openModalCheckAddress()">
        Sign Up
      </button>
      }
    </div>
    }
  </div>
  } -->
</div>
} @else {
<div class="mb-4 animated fadeInUp fadeIn col">
  <div class="card position-relative p-3 row-gap-3" aria-hidden="true">
    <span class="placeholder bg-secondary col-12"></span>


    <span class="placeholder bg-secondary col-6"></span>
    <span class="placeholder bg-secondary col-6"></span>
    <div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
    <div class="card-body">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder bg-secondary col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder bg-secondary col-7"></span>
        <span class="placeholder bg-secondary col-4"></span>
        <span class="placeholder bg-secondary col-4"></span>
        <span class="placeholder bg-secondary col-6"></span>
        <span class="placeholder bg-secondary col-8"></span>
      </p>
      <a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
    </div>
  </div>
</div>
}
